import React from 'react'
import logo from './logo.svg';
import './logo.scss';

function Logo() {
  return (
    <div className="crp-logo">
      <a href="/">
        <img src={logo} alt="logotype"/>
        <div className="crp-logo--text">CROP.DOG</div>
      </a>
    </div>
  )
}

export default Logo;