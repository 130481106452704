import React from 'react'
import './stat.scss';
import axios from 'axios'

class Stat extends React.Component {
  constructor() {
    super()

    this.state = {
      stat: null
    }
  }

  componentWillMount() {
    this.fetchStat();
  }

  fetchStat() {
    axios.get('/stat')
      .then(
        response => {
          this.setState({
            stat: response.data
          })
        }
      )
  }

  render() {
    return (
      <div className="crp-stat">
        {this.state.stat &&
          <div>
            <div className="total">{this.state.stat.links_shortened}</div>
            <div className="descr">URL's SHORTENED</div>
          </div>
        }
      </div>
    )  
  }
}

export default Stat;