import React from 'react'
import './error.scss'

function Error(props) {
  return (
    <div className="crp-error">
      <h1>{props.code}</h1>
      <h2>
        <p>The service is currently unavailable.</p>
        <p>It may be overloaded or down for maintenance.</p>
      </h2>
    </div>
  )
}

export default Error;