import React from 'react'
import './linkShorterForm.scss'
import Loader from '../Loader/Loader'
import ClipboardJS from 'clipboard'
import axios from 'axios'

class LinkShorterForm extends React.Component {
  constructor(props) {
    super(props);

    this.clipboard = null;

    this.state = {
      link: '',
      error: null,
      submitting: false,
      shortLink: null,
      showCopied: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetLink = this.resetLink.bind(this);
  }

  onSubmit(ev) {
    ev.preventDefault()

    this.setState({ submitting: true })
    axios.post('/link', { link: this.state.link })
      .then(
        response => {
          this.setState({
            shortLink: response.data.short_link,
            submitting: false,
            error: null,
            link: ''
          })
        },
        error => {
          this.setState({ submitting: false })
          this.setState({ error: error.response.data.message })
        }
      )
  }

  handleChange(ev) {
    this.setState({ link: ev.target.value });
  }

  resetLink(ev) {
    this.setState({ shortLink: null })
  }
  
  componentWillMount() {
    if (ClipboardJS.isSupported()) {
      this.setState({clipboardSupported: true})

      this.clipboard = new ClipboardJS('.copy')
  
      this.clipboard.on('success', () => {
        this.setState({showCopied: true})
        setTimeout(() => {    
          this.setState({showCopied: false})
        }, 3000)
      })
  
      this.clipboard.on('error', () => {
        alert('Something went wrong, please copy link manually')
      })  
    }
  }

  componentWillUnmount() {
    this.clipboard && this.clipboard.destroy();
  }

  render() {
    return (
      <div className="crp-link-shorter">
        {(!this.state.shortLink ?
          <form onSubmit={this.onSubmit}>
            <div className="inputGroup">
              <input 
                className="link"
                type="text"
                placeholder="Shorten your link"
                value={this.state.link}
                onChange={this.handleChange}
                disabled={(this.state.submitting ? 'disabled' : '')}
              />
              {this.state.error && 
                <span className="error">{this.state.error}</span>
              }
            </div>
            <button className="submit" type="submit">
              <span style={{visibility: this.state.submitting ? 'hidden' : 'visible'}}>Shorten</span>
              {this.state.submitting && <Loader/>} 
            </button>
          </form>
          :
          <div className="copyFrame">
            <div className="inputGroup" onClick={this.handleCopyClick}>
              <input 
                className="short-link copy"
                type="text"
                value={this.state.shortLink}
                disabled="disabled"
                data-clipboard-text={this.state.shortLink}
              />
              {this.state.clipboardSupported &&
                <div className="click-to-copy">
                  {this.state.showCopied ? 'copied' : 'click to copy'}
                </div>
              }
            </div>
            <div className="new-link" onClick={this.resetLink}>create new link</div>
          </div>
        )}
      </div>
    )  
  }
}

export default LinkShorterForm;