import React from 'react'
import './loader.css'

function Loader() {
  return (
    <div className="crp-loading">
      <div className="lds-dual-ring"></div>
    </div>
  )
}

export default Loader;